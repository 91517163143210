// NOTE: initial code is cloned from https://github.com/wix-private/santa-core/blob/master/santa-main-r/src/lib/common/loggerFactory.js

import {
  PublishMethods,
  factory as createBILogger,
} from '@wix/web-bi-logger/dist/src/logger';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import type { Factory as BILoggerFactory } from '@wix/web-bi-logger/dist/src/logger';
import type { FedopsLogger as BaseFedopsLogger } from '@wix/fedops-logger';
import type { BILoggerModel } from 'types/core';
import type { LoggerOptions } from '../types';
import type { EditorParams } from '@/editorParams';

const BI_ENDPOINT = 'editor-performance';
const PRESET = 'EDITOR';

export type { BILoggerFactory, BaseFedopsLogger };

const createEditorFedopsLogger = <TInteractionName>({
  biLoggerModel,
  editorParams,
  options,
}: {
  biLoggerModel: Partial<BILoggerModel>;
  editorParams: EditorParams;
  options: LoggerOptions;
}) => {
  const biLoggerFactory = createBILogger({
    publishMethod: PublishMethods.Auto,
    useBatch: options.useBatch,
  })
    // .setMuted()
    .withUoUContext({
      msid: biLoggerModel.metaSiteId,
      visitorId: biLoggerModel.editorSessionId,
      siteMemberId: () => biLoggerModel.siteMemberId,
    })
    .updateDefaults({
      is_rollout: biLoggerModel.is_rollout,
      isTabDuplicated: editorParams.isTabDuplicated,
      origin: biLoggerModel.origin,
      type: 'none',
      // NOTE: ts is used to show not only duration of phase, but also start of phase
      // example: https://tableau.wixpress.com/#/site/Editor/views/EDIXperformancedashboardbasedonfedops-daily/LoadingPhasesGantt?:iid=5
      ts: () => Math.round(window.performance.now()),
    });

  const fedopsLogger = createFedopsLogger<TInteractionName>(
    biLoggerModel.fedOpsAppName,
    {
      // NOTE: endpoint is real param, but not available in types
      // https://github.com/wix-private/fed-infra/blob/9feba947b862614301e0057fda2b35f4dd348b86/fedops/fedops-logger/src/reporter/reporter-factory.js#L9
      // @ts-ignore
      endpoint: BI_ENDPOINT,
      isServerSide: false,
      biLoggerFactory,
      phasesConfig: 'SEND_ON_FINISH',
      metasiteId: biLoggerModel.metaSiteId,
      presetType: PRESET,
      paramsOverrides: {
        // details: https://wix.slack.com/archives/CQGJP31CM/p1610723500080300?thread_ts=1610722235.076400&cid=CQGJP31CM
        is_rollout: biLoggerModel.is_rollout,
      },
      useBatch: options.useBatch,
    },
  );

  if (options.disableBatchForLoadingPhases) {
    const phaseStart = fedopsLogger.appLoadingPhaseStart.bind(fedopsLogger);
    const phaseFinish = fedopsLogger.appLoadingPhaseFinish.bind(fedopsLogger);
    const appLoaded = fedopsLogger.appLoaded.bind(fedopsLogger);
    const flush = fedopsLogger.flush.bind(fedopsLogger);

    // Check the theory that "fedops batch affect the app loading success rate data"
    // Flush function, will send all pending events, so batch will net be there.

    fedopsLogger.appLoadingPhaseStart = (...args) => {
      flush();
      phaseStart(...args);
      flush();
    };

    fedopsLogger.appLoadingPhaseFinish = (...args) => {
      flush();
      phaseFinish(...args);
      flush();
    };

    fedopsLogger.appLoaded = (...args) => {
      flush();
      appLoaded(...args);
      flush();
    };
  }

  return {
    logger: fedopsLogger,
    updateDefaults: (params: Record<string, any>) => {
      biLoggerFactory.updateDefaults(params);
    },
  };
};

export { createEditorFedopsLogger };
