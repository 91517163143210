import { getCookie } from './utils';
import type { EditorModel } from 'types/core';
import type { ValuesOfReturnFnValues } from './types';
import type { BiLoggerInitialParams } from 'types/bi';
import type { EditorParams } from '@/editorParams';

const getBsiParams = () => {
  const bsiCookie = getCookie('bSession');

  if (!bsiCookie) {
    return {};
  }

  const [bsi, bsi_page_number] = bsiCookie.split('|');

  return {
    browsing_session: bsi,
    bsiPageNumber: bsi_page_number,
    bsi: bsiCookie,
  };
};

export const getBILoggerInitialParams = ({
  editorModel,
  editorParams,
}: {
  editorModel: EditorModel;
  editorParams: EditorParams;
}) => {
  const {
    editorBase,
    metaSiteId,
    editorSessionId,
    permissionsInfo,
    siteHeader,
  } = editorModel;

  const params: ValuesOfReturnFnValues<BiLoggerInitialParams> = {
    ownerId: permissionsInfo?.ownerId,
    // TODO: do we need this 'ownerId` duplication?
    // @yinonc
    // > you need uuid in our BI for validation needs (BI is invalid without uuid)
    uuid: permissionsInfo?.ownerId,
    roles: permissionsInfo?.loggedInUserRoles
      .map((userRole: any) => userRole.role)
      .join(','),
    // TODO: esi is param sent by default, remove esi from the event sending places:
    // https://github.com/wix-private/santa-editor/blob/5302299edf2dab9e21de8156d6e9195d60fa2db2/santa-editor/packages/compPanels/panels/languageSelector/languageSelectorManagePanelMapper.ts#L38
    // https://github.com/wix-private/santa-editor/search?q=esi
    esi: editorSessionId,
    is_rollout: editorParams.isRollout,
    editor_version: editorBase.split('/').pop() || editorBase,
    ts: () => Math.round(Date.now() - (window.mainLoaded || 0)),
    msid: metaSiteId,
    site_id: siteHeader?.id,
    ...getBsiParams(),
  };

  // performance.memory is a non-standard extension of chrome
  const memoryInfo = (window?.performance as any)?.memory;

  if (memoryInfo) {
    params.totalJsHeapSize = () => memoryInfo?.totalJSHeapSize;
    params.usedJsHeapSize = () => memoryInfo?.usedJSHeapSize;
    params.jsHeapSizeLimit = () => memoryInfo?.jsHeapSizeLimit;
  }

  return params;
};
